import logo from './logo.svg';
import './App.css';
import MarketingPage from './marketing-page/MarketingPage';

function App() {
  return (
    <>
    <MarketingPage/>
    </>
  );
}

export default App;
